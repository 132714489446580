import { Box, BoxProps, Button } from 'grommet'
import React from 'react'

interface Props extends BoxProps {
  item: string
}

export default function Chip({ item, ...rest }: Props) {
  return (
    <Box {...rest}>
      <Button
        label={item}
        size="small"
        onClick={() => {
          console.log('should go to the page where all the photos with this tag  are available')
        }}
      />
    </Box>
  )
}
