import mapboxgl from "mapbox-gl"
import React from 'react'

export default function Marker({ map, latitude, longitude }: { map: mapboxgl.Map, latitude: number; longitude: number }) {
  const markerRef = React.useRef()

  React.useEffect(() => {
    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(map)

    return () => marker.remove() as unknown as void
  }, [map])

  return <div ref={markerRef} />
}
