import { graphql, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Grid, Heading, Markdown, Paragraph, ResponsiveContext, Text } from 'grommet'
import { Previous } from 'grommet-icons'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { FaCopyright, FaRegCalendarAlt, FaTags } from 'react-icons/fa'
import styled from 'styled-components'
import Chip from '../components/Chip'
import Map from '../components/Map'
import HeaderLayout from '../layouts/HeaderLayout'

const StyledGatsbyImage = styled(GatsbyImage)`
  border-radius: 6px;
`


export default function Photo({ data }) {
  const { GPSLatitude, GPSLongitude } = data.file.fields.metadata
  const size = React.useContext(ResponsiveContext)
  return (
    <Box>
      <HeaderLayout>
        <Button icon={<Previous />} onClick={() => navigate(-1)} />
      </HeaderLayout>

      <Box fill align="center" gap="none" pad="none">
        <Box width={size !== 'small' ? 'xlarge' : '100%'} gap="none" pad="none">
          <Grid gap="small" pad="medium">
            <Box>
              <StyledGatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                alt={data.file.fields.metadata.Title}
              />
            </Box>
            <Box background="background-front" pad="small" fill round="xsmall">
              <Grid gap="small">
                <Box pad="small" direction="column" align="start" gap="small">
                  <Heading margin="none">{data.file.fields.metadata.Title} </Heading>
                </Box>
                {data.file.fields.metadata.Caption && <Box pad="small" direction="row" align="center" gap="small">
                  <Paragraph margin="none" fill>
                    <Markdown>
                      {data.file.fields.metadata.Caption}
                    </Markdown>
                  </Paragraph>
                </Box>}
                <Box pad="small" direction="row" align="center" gap="small">
                  <Text>Camera: {data.file.fields.metadata.Model}</Text>
                </Box>
                <Box pad="small" direction="row" align="center" gap="small">
                  <Text>Lens: {data.file.fields.metadata.Lens}</Text>
                </Box>

                <Box pad="small" direction="row" align="center" gap="small">
                  <FaRegCalendarAlt />
                  <Text>{new Date(data.file.fields.metadata.CreatedDate).toLocaleString('ES')}</Text>
                </Box>
                <Box pad="small" direction="row" align="center" gap="small">
                  <FaTags />
                  <Box fill direction="row" gap="small" wrap>
                    {data.file.fields.metadata.Keywords.map((k: string, i: number) => {
                      return <Chip key={i} item={k} pad="small"></Chip>
                    })}
                  </Box>
                </Box>
                <Box pad="small" direction="row" align="center" gap="small">
                  <FaCopyright />
                  <Text>{data.file.fields.metadata.Copyright}</Text>
                </Box>
                {GPSLatitude && GPSLongitude && (
                  <Box height="small" fill pad="small" direction="row" align="center" gap="small">
                    <Map
                      latitude={GPSLatitude}
                      longitude={GPSLongitude}
                    />
                    {/* <StaticMap
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    width="100vw"
                    height="50vh"
                    latitude={GPSLatitude}
                    longitude={GPSLongitude}
                    zoom={8}
                    mapStyle="mapbox://styles/mapbox/dark-v9?optimize=true'"
                  >
                    <Marker
                      latitude={GPSLatitude}
                      longitude={GPSLongitude}
                      offsetLeft={-20}
                      offsetTop={-10}
                    >
                      <Box
                        style={{
                          height: 25,
                          width: 25,
                          backgroundColor: '#673ab7',
                          borderRadius: '50%',
                          display: 'inline-block',
                          opacity: 0.8,
                        }}
                      />
                    </Marker>
                  </StaticMap> */}
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>

  )
}

export const MyPhoqoQuery = graphql`
  query MyPhoto($cid: String) {
    file(fields: { cid: { eq: $cid } }) {
      fields {
        metadata {
          Keywords
          Title
          Caption
          Creator
          CreatedDate
          Copyright
          Rights
          CopyrightNotice
          GPSLatitude
          GPSLongitude
          Model
          Lens
          LensModel
        }
      }
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          transformOptions: { fit: COVER, cropFocus: ENTROPY }
        )
      }
    }
  }
`
