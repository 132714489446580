import mapboxgl from "!mapbox-gl" // this is the way to tell webpack to exclude it from the post-processing https://webpack.js.org/concepts/loaders/#inline
import { Box } from 'grommet'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { MAPBOX_TOKEN } from '../config'
import Marker from './Marker'
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // Load worker code separately with worker-loader


// mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

const mapContainerStyle = {
  width: "100vw",
  height: "50vh"
}

export default function Map({ latitude, longitude }: { latitude: number; longitude: number }) {
  const mapContainerRef = React.useRef(null)
  const [map, setMap] = React.useState(null)

  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: 'mapbox://styles/mapbox/dark-v9?optimize=true',

      // Empire State Building [lng, lat]
      center: [longitude, latitude],
      zoom: 10,
    })

    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    setMap(map)

    return () => map.remove()
  }, [])



  return (
    <Box ref={mapContainerRef} style={mapContainerStyle}>
      { map && <Marker map={map} latitude={latitude} longitude={longitude} />}
    </Box>
  )
}

